.Christmas_Banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    background-color: var(--weber);
    color: white;
    font-size: 16px;
    text-align: center;
    line-height: 120%;
    display: none;

}

.Christmas_Banner > p {
    margin: 10px;
}

