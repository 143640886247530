.home_referenzen {
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;

} 

.home_referenzen_text {
    display: flex;
    margin-top: 2%;
    margin-bottom: 2%;

} 

.home_referenzen_text > p {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}

.home_referenzen_fotos {
    width: 88vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 2%;
}

.home_referenzen_foto > img {
    width: 150px;
}


@media (max-width:840px) {

    .home_referenzen_fotos { 
display: grid;
grid-template-columns: repeat(2, 2fr);
justify-content: space-evenly;
align-content: center;
justify-items: center;
align-items: center;
}

.home_referenzen_foto {
    margin-top: 50px;
}

.home_referenzen_text {
    margin: 0 6% 0 6%;
}

}