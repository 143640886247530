
.header {
    height: 9vh;
}

.header_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    width: auto;
    height: 9vh;
    top: 0;
    z-index: 10;
    background-color: rgb(255, 255, 255);
    border-bottom: solid 1px rgb(248, 248, 248);

}

 
.header_logo > img {
    width: 150px;
    margin-top: 8px;
    object-fit: contain;
    margin-left: 6vw;
    
}

.header_logo2 > img {
    display: none;
    
}

.header_left {
    display: flex;
    align-items: center;
    margin-right: 6vw;

}

.produkt_header_schrift.active {
    color: var(--weber);
}


.header_left > .link_button_service {
    margin-left: 50px;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 400;
    transition: transform 100ms ease-in;
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
 
}

.header_left > .link_button_service_2 {
    margin-right: 15px;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 400;
    transition: transform 100ms ease-in;
    color: black;
    text-decoration: none;
 
}

.header_left > .link_button_service.active {
    color: var(--weber);
}

.header_left > .link_button_service_2.active {
    color: var(--weber);
}



.header_left >  .header_parent_wrapper > .produkt_header_schrift {
    margin-left: 50px;
    margin-right: 10px;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 400;
    transition: transform 100ms ease-in;
 
}

.header_left > .header_parent_wrapper > .link_button_service:hover,
.header_left > .link_button_service:hover {
    transform: scale(1.03);
    cursor: pointer;
 
}

.header_left > .header_parent_wrapper > .produkt_header_schrift {
    margin-left: 50px;
    margin-right: 10px;
    letter-spacing: 0.5px;
    font-size: 16px;
    font-weight: 400;
    transform: scale(1.03);
    cursor: pointer;
 
}

.header_parent_wrapper {
    position: relative;
} 


.dropdown_menu {
    display: none;
}

.header_parent_wrapper:hover .dropdown_menu {
    position: absolute;
    right: 0;
    text-align: right;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    padding: 15px 5px 0 10px ;
    display: block;
    min-width: 110px;
}


.dropdown_menu > nav > a,
.dropdown_menu > nav > p {
    margin: 15px 15px ;
    color: rgb(58, 58, 58);
    text-decoration: none;
    display: block;
} 

.dropdown_menu > nav > a:hover,
.dropdown_menu > nav > p:hover {
    transform: scale(1.03);
    cursor: pointer;
    color: var(--weber);
    font-weight: 600;

} 




.header_mobile {
    display:none;
}




#kontakt_button {
    color: white;
    background-color: var(--weber);
    padding: 6px 30px 8px 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
} 




@media (max-width: 1050px) {
    
    
    .header_logo > img {
  width: 150px;
        
    }

.header_logo2 {
    display: none;
}

    .header_logo2 > img {
        display: flex;
        width: 120px;
        margin-top: 8px;
        object-fit: contain;
        
    }
    
    .header_left {
        display:none;

    }

    .header_right {
        display:none;
    }

    .header_mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
    }

    .header_mobile_right {
        display: flex;
        align-items: center;
    }



    .header_mobile_button {
        display: none;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-right: 20px;
        background-color: var(--weber);
        padding: 0px 20px 0px 20px;
        height: 35px;
        border-radius: 100px;
    }

    .header_mobile_button:hover {
        cursor: pointer;
    }

    .header_mobile_button > h4 {
        color: white;
        font-weight: 500;
    }



    .header_mobile_button > img {
        width: 20px;
        padding-left: 10px;
        filter: invert();
        
        
    }

    .header_mobile_menu > img {
        height: 35px;
        margin-right: 15px;
        margin-top: 15%;
    }

    .header_mobile_menu > img:hover {
        cursor: pointer;
        transform: scale(1.05);
    }


    .mobile_nav {
        height: 100vh;
        width: 80vw;
        position: fixed;
        top: 0;
        right: 0;
        background-color: rgb(81, 128, 81);
        transition: 800ms;
        z-index: 100;
        
    }

    .mobile_nav_header {
        display: flex;
        align-items: center;
       justify-content: flex-end;
    }
    .mobile_nav_header > img {
        margin-top: 20px;
        width: 30px;
        margin-right: 15px;
        filter: invert();
    }

    .mobile_nav_header > img:hover {
       cursor: pointer;
       filter: none;
    }

    .mobile_nav_kategorie {
        display: flex;
        flex-direction: column;
       margin-left: 10%;
       margin-top: 5%;
    }

    .mobile_nav_kategorie > hr {
        margin-top: 10px;
        margin-right: 15px;
        border: 1px solid rgb(7, 54, 1);;
        margin-bottom: 10px;
        
    }

    .mobile_nav_kategorie > h4 {
        font-weight: 400;
        letter-spacing: 1px;
        color: rgb(255, 255, 255);
    }

    .mobile_navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .mobile_navigation > nav > p {
        text-align: right;
        font-size: 22px;
        font-weight: 400;
        margin: 15px 15px 0px 0;
        color: white;
    }

    .mobile_navigation > nav {
        display: flex;
        flex-direction: column;
        text-align: right;
    }

    .mobile_navigation > nav > .nav_styling {
        text-decoration: none;
        font-size: 20px;
        font-weight: 400;
        margin: 10px 15px 0px 0;
        color: rgb(255, 255, 255);
    } 

    .mobile_navigation > nav > p:hover {
        color: var(--weber);
        transform: scale(1.04);
        cursor: pointer;
    }

    .mobile_navigation > nav > .nav_styling:hover {
        color: var(--weber);
        transform: scale(1.04);
        cursor: pointer;
    } 


}



