.weber_header {
    height: 91vh;
}

.weber_header > img {
    width: 100vw;
    object-fit: cover;
    height: 100%;
}

.weber_textbar {
    margin-left: 6%;
    position: absolute;
    top: 28%;
}


.weber_textbar > h1 {
    font-size: 52px;
    color: black;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: -6px;
}

.weber_textbar > p {
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 16px;
}

.weber_textbar > .button_link_header {
    width: 250px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: var(--weber);
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 30px;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 300ms ease;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.weber_textbar > .button_link_header:hover {
    transform: scale(1.03);
    cursor: pointer;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}


#mobile_pic {
    display: none;
}

@media (max-width:1040px) {
    #desktop_pic {
        display: none;
    }

    .weber_textbar {
        top: 24%;
    }

    #mobile_pic {
        display: block;
        filter: opacity(100%)
    }

    .weber_textbar > h1 {
        font-size: 44px;
        margin-right: 30px;
    }

    .weber_textbar > p {
        font-weight: 300;
        letter-spacing: 1px;
        margin-right: 40px;
    }

}