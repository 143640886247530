.kontakt-1 {
    overflow-y: auto; /* Aktiviert vertikales Scrollen */
    padding: 16px; /* Optional: Innenabstand für einen schöneren Look */
    box-sizing: border-box; /* Berücksichtigt Padding in der max-height */
  }

.kontakt {
    display: flex;
    flex-direction: column;
    position: fixed;

}

.kontakt_alternative {
    display: flex;
    flex-direction: column;
}

.produkt_kontakt_head {
    display: flex;
}

.produkt_kontakt_head > img {
    width: 30px;
    margin-left: 15px;
}

.produkt_kontakt_head > h4 {
    font-size: 26px;
    margin-bottom: 5px;
}

.produkt_kontakt > h4 {
    font-size: 25px;
    margin-bottom: 5px;
}

.produkt_kontakt > p {
    margin-right: 6vw;
}



.formular {
    display: flex;
    justify-content: right;
    margin-left: 0%;
    margin-right: 10%; 
    align-items: center;
    margin-top:3%;
}



.formular > form {
    display: flex;
    flex-direction: column;
    flex: 1;
 }

 .formular > form > button {
     width: 100%;
     height: 55px;
     border-radius: 100px;
     border: none;
     background-color:var(--weber);
     color: white;
     font-weight: 500;
     font-size: 14px;
     margin-top: 5px;
     letter-spacing: 2px;
     cursor: pointer;
     transition: all 200ms ease;

 }

 .formular > form > button:hover {

    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.35);


}


 .input {
     height: 45px;
     margin: 0px 0 12px 0;
     font-weight: 500;
     letter-spacing: 1px;
     border-color: transparent;
     border-radius: 5px;
     border: none;
     padding-left: 20px;
     background-color: rgb(241, 241, 241);
     font-family: 'Noto Serif JP';
 }

 .input2 {
    -webkit-appearance:none;
    -moz-appearance: none; 
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: rgba(231, 231, 231, 0.644);
    width: 75%;
    height: 45px;
    margin: 5px 0 15px 0;
    font-weight: 500;
    letter-spacing: 2px;
    border-color: transparent;
    border-radius: 5px;
    border-right-width: 10px;
    padding-left: 20px;
 
}

.input3 {
    -webkit-appearance:none;
    -moz-appearance: none; 
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='green'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    outline: none;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: rgba(231, 231, 231, 0.644);
    height: 45px;
    margin: 5px 0 15px 0;
    font-weight: 500;
    letter-spacing: 2px;
    border-color: transparent;
    border-radius: 5px;
    padding-left: 20px;
 
}

.input4 {
    -webkit-appearance:none;
    -moz-appearance: none; 
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='green'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: rgba(231, 231, 231, 0.644);
    width: 100%;
    height: 48px;
    margin: 0px 0 10px 0;
    font-weight: 500;
    letter-spacing: 2px;
    border-color: transparent;
    border-radius: 5px;
    padding-left: 20px;
 
}

 .input::placeholder {
     color: gray;
     transition: color 0.3s ease;
 }

 .input:focus::placeholder {
     color: transparent;
 }

 .input:hover {
    font-weight: 500;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
    

}
.input:focus {
    font-weight: 500;
    border:none;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}

 .checkbox_container {
     font-size: 14px;
     letter-spacing: 1px;
     display: flex;
     align-items: center;
 
 }

 .checkbox_container > label {
    cursor: pointer;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
}
 
 .checkbox_container > input[type="checkbox"] {
     cursor: pointer;
     opacity: 0;
     position: absolute;
 }

.checkbox_container > label::before {
    content: "";
    width:1.5em;
    height: 1.5em;
    border: 2px solid rgb(172, 172, 172);
    border-radius: 100px;
    margin-right: 0.5em;
    background-color: white;
}

.checkbox_container > label:hover::before, 
.checkbox_container > input [type ="checkbox"]:hover + label::before {
    border: 2px solid rgb(132, 216, 64) ;
}

.checkbox_container > input[type="checkbox"]:focus + label::before {
    box-shadow: 0 0 px black;
}

.checkbox_container > input[type="checkbox"]:checked + label::before {
    background-color: rgb(132, 216, 64) ;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.kontakt_foto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

}

.kontakt_foto > img {
    display: flex;
    width:  70%;
    height: 60%;
    object-fit: cover;
    border-radius: 20px;
    z-index: 2;
}

.input5 {
    height: 90px;
    margin: 0px 0 0px 0;
    border-color: transparent;
    border-radius: 5px;
    outline: none;
    resize: none;
    border: none;
    padding-left: 15px;
    padding-top: 10px;
    background-color: rgb(241, 241, 241);
    font-family: 'Noto Serif JP';
}

.input_6 {
display: none;
}

.produkt_hidden {
    display: none;
}

.image-upload-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-upload-container-wrapper > img {
    width: 25px;
    opacity: 0.5;
    margin-right: 10px;
}

.image-upload-container-wrapper:hover > img {
    opacity: 1;
}

.image-upload-container-wrapper:hover {
    color: black;
}

.image-upload-container {
    position: relative;
    margin: 15px 0px 0 0px;
    padding: 5px 0;
    min-height: 30px;
    max-height: 120px;
    border: 1px dashed #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    display: inline-block;
  }
  
  .image-upload-container span {
    font-size: 14px;
    color: #999;
    text-align: center;
  }
  
  .image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  


  .hidden-image-input {
    display: none;
  }
  

  .submit-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  



@media (max-width: 1040px) {

    .formular {
        margin-right: 6%;
    }
    
    .kontakt {
        margin-left: 6%;
        margin-bottom: 5%;
        position: relative;
        margin-top: 5%;

    }       

    .kontakt_alternative {
        margin-left: 6%;
        margin-bottom: 10%;
        margin-top: 5%;
    }

    .input5 {
        width: 100%;
    }

    .input.input {
        width: 100%;
    }

    .input3 {
        width: 102.5%;
    }

    .input4 {
        width: 100%;
    }


    .kontakt_links {
        
        height: 110vh;
       
    }

  


.kontakt_info > h1 {
    font-weight: 500;
    letter-spacing: 1px;
    font-size: 4em;
 
 }

 .input {
    width: 100%;
}

.input2 {
    width: 100%;
}

.checkbox_container > label::before {
    content: "";
    width:30px;
    height: 30px;
    border: 2px solid rgb(172, 172, 172);
    border-radius: 100px;
    margin-right: 10px;
    background-color: white;
}

.formular > form > button {
    width: 100%;
    height: 50px;
    border-radius: 100px;
    border: none;
    background-color: var(--weber);
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 80px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 500ms ease;
}



    .kontakt_foto > img {
        display: none;
    }

}


@media (max-width: 600px) {
    .input3 {
        width: 105%;
    }
}