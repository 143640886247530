.kontakt_success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 50vw;
    align-items: center;
    text-align: center;
    background-color: rgba(241, 241, 241, 0.507);
    padding: 30px 5px;
    border-radius: 10px;
}

.kontakt_success_video {
    width: 90px;
    margin-top: -30px;
    margin-bottom: -10px;
}

.kontakt_success_text > h3 {
    font-size: 24px;
    color: var(--weber);
    margin-bottom: 1%;
}

.kontakt_success_img {
    display: flex;
    align-items: center;
    justify-content: center;
}



.kontakt_success_img > img {
    margin-top: 3%;
    width: 80%;
    border-radius: 5px;
}

@media (max-width: 1050px)  {
    .kontakt_success {
        display: flex;
        max-width: 100vw;
        justify-content: center;
        align-items: center;
    }

    .kontakt_success_text {
        text-align: center;
    }
    .kontakt_success_img {
        display: flex;
        justify-content: center;
    }
    .kontakt_success_img > img {
        margin-top: 3%;
        width: 90%;
    }

}