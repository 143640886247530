
.produkt_cta {
    display: flex;
    margin-top: 5%;
    margin-bottom: 10%;
}

.produkt_cta_container {
    display: flex;
    justify-content: space-between;
    background-color: rgb(243, 242, 242);
    border: 2px solid rgb(250, 250, 250);
    width: 90%;    
    border-radius: 10px;
    max-height: 200px;
    min-height: 200px;
}




.produkt_cta_links {
    width: 30%;
}

.produkt_cta_mitte {
    margin-bottom: 5%;
    margin-top: 5%;
    color: var(--weber);


}

.produkt_cta_mitte > p {
    font-size: 16px;
}

.produkt_cta_mitte > h3 {
    font-size: 28px;
    margin-bottom: 2%;
}

.produkt_cta_mitte > h4 {
    font-size: 16px;
    font-weight: 300;
}

.produkt_cta_mitte > h4 > span {
    font-size: 18px;
    font-weight: 700;

}


.produkt_cta_rechts {
    width: 10%;
}

.produkt_cta_rechts > img {
    margin-top: 20%;
    width: 50px;
}

.produkt_cta_links > img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius:10px;
    border-bottom-left-radius: 10px;

}

@media (max-width: 950px) {

    .produkt_cta {
        display: none;
        align-items: center;
        justify-content: center;
    }

    .produkt_cta_container {
        width: 100%;   
        max-width: 700px; 
    }

    .produkt_cta_links {
        display: none ;
}


.produkt_cta_mitte {
    margin-lefT: 4%;
    color: var(--weber);
}

.produkt_cta_rechts > img {
    margin-top: 20%;
    width: 35px;
}
}