/* Container für das Login-Formular */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .login-form {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login-form h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #005500;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #006600;
  }
  
  .login-error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  