
.youtube_video {
    margin-top: 10px;
}


.home_features {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
}

.home_features_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 8% 0 10px 0;
    text-align: center;
}

.home_features_top > p {
   max-width: 70%;
   margin-top: 1%;
   font-size: 1rem;
}

.home_features_top > hr {
    width: 10%;
    border: none;
    height: 1px;
    margin: 2% 0 2% 0;
    background-color: rgb(184, 184, 184);
} 

.home_features_top > h2 {
    color: var(--weber);
}

.home_features_bottom {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5%;

}

.home_feature {
    text-align: center;
    margin: 20px 5% 0 5%;
    max-width: 20%;
} 

.home_feature > img {
    height: 80px;
    margin-bottom: 5%;
} 

.home_feature > h3 {
    margin-bottom: 5%;
}


@media (max-width: 840px) {

    .youtube_video {
        width: 400px;
        height: 240px;
        margin-bottom: 20px;
    }

    
    .home_features_bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    } 

    .home_features_top {

        margin: 8% 0 0px 0;
    
    }

    .home_features_top > p {
        max-width: 80%;
    }

    .home_features_top > h2 {
        margin-bottom: 1%;
        max-width: 80%;
      
    }

    .home_feature {
        text-align: center;
        max-width: 60%;
        margin-top: 5%;
        margin-bottom: 5%;
    } 

    .home_features_top > hr { 
    }


    @media (max-width: 540px) {
        .youtube_video {
            width: 300px;
            height: 180px;
            margin-bottom: 20px;
        }
    }

}