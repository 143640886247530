.home_product {
    display: flex;
    flex-direction: column;
    width: 20vw;
    align-items: center;
    margin-top: 1%;
    color: black;
    text-decoration: none;
    transition: all 300ms ease;
    margin-bottom: 5%;
   
  

}


.home_product:hover > h2 {
    color: var(--weber);
    -moz-transition:    color 0.5s;
    -ms-transition:     color 0.5s;
    -o-transition:      color 0.5s ease-out; 
    transition:         color 0.5s ease-out;
    text-decoration: underline rgb(209, 209, 209);
    text-underline-offset: 10px;
   
    
    

}

.home_product:hover {
    transform: scale(1.04);
    cursor: pointer;
    opacity:0.9;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; 
    transition: all 500ms ease;

}

.home_product > img {
    width: 100%;
    transition: all 500ms ease;
}

.home_product > h2 {
    color: black;  
    font-weight: 800;  
    font-size: 16px;
}

.link {
    text-decoration: none;
}

.link_button {
    margin-top: 10px;
    color: black;
    text-decoration: none;
    padding: 0px 10px 5px 10px;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: none;


}


.link_button > p {
    margin-top:2px;
}

.home_product:hover {
    border-radius: solid black 100px;
    color: var(--weber);
    -moz-transition:    color 1s;
    -ms-transition:     color 1s;
    -o-transition:      color 1s ease-out; 
    transition:         color 1s ease-out;
}

@media (max-width:840px) {

    .home_product {
        width: 40vw;
        text-align: center;
    }


  
}