

.produkt_kontakt_2 {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    width: 40vw;
}

.produkt_information {
    display: flex;
    margin-top: 6%;
    margin-bottom: 6%;
}


.produkt_wrapper {
    margin-left: 6%;
}


.produkt_kontakt_container {
    display: flex;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(238, 238, 238);
    width: 100%;    
    border-radius: 10px;
    margin-right: 50px;
}


.produkt_kontakt_mitte {
    margin-bottom: 5%;
    margin-top: 5%;
    color: var(--weber);
    margin-left: 20px;


}

.produkt_kontakt_mitte > p {
    font-size: 16px;
    color: black;
}

.produkt_kontakt_mitte > h3 {
    font-size: 22px;
    margin-bottom: 12%;
}

.produkt_kontakt_mitte > h4 {
    font-size: 16px;
    font-weight: 300;
}

.produkt_kontakt_mitte > h4 > span {
    font-size: 18px;
    font-weight: 700;
    color: black;

}

.extra_space {
    height: 20vh;
}

.produkt_kontakt_rechts {
    width: 10%;
    margin-right: 15px;

}

.produkt_kontakt_rechts > img {
    margin-top: 20%;
    width: 50px;
}



@media (max-width: 1050px) {

    .produkt_page_2 {
        margin-left: 0px;
    }

    .produkt_wrapper {
        margin-left: 0px;
    }

    .produkt_information {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }


    .produkt_kontakt_2 {
        width: 92vw;
        max-width: 700px;
        margin-left: 0;
        margin-right: 0;
    }
    .produkt_kontakt_rechts > img {
        margin-top: 30%;
        width: 40px;
    }

    .produkt_kontakt_container {
        margin-bottom: 20px;
    }

    .produkt_kontakt_container {
        margin-right: 0px;
    }
}