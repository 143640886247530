.top_pic  {
display: flex;
 width: 100vw;
 height: 200px;
 align-items: center;
background-color: rgb(1, 39, 1);
background-image: linear-gradient(rgba(12, 22, 15, 0.705), rgba(19, 11, 0, 0.74)), url("./img/Heidelberger.jpg");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
position: relative;
}



.top_pic > h1 {
    margin-left: 6%;
    font-size: 48px;
    color: white;
}

