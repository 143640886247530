.popular-product-analytics {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 70px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .popular-product-analytics h2 {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .popular-product-analytics {
    position: relative;
    height: 400px;
    width: 100%;
  }
  
  .chart-container-2 {
      display: flex;
      width: 60%;
      align-items: center;
      justify-content: center;
  }