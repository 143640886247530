.month-analytics {
    text-align: center;
    margin: 20px 0;
  }
  
  .chart-container {
    width: 35%;
    margin: 0 auto;
  }
  
  .chart-container canvas {
    max-width: 100%;
  }
  