.footer {
    display: flex;
    height: 50px;
    background-color: #f7f7f7;
}

.footer_elements {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-left: 6%;
    margin-right: 6%;

}

.footer > .footer_elements > .link_agb {
    text-decoration: none;
    color: var(--weber);
    margin: 0 1% 0 0%;
    cursor: pointer;
}

.footer > .footer_elements > p {
    display: flex;
    align-items: center;
    justify-content: center;   
    color: var(--weber); 
    font-weight: 500;
    font-size: 15px;
} 

.footer > .footer_elements > .link_agb:hover {
transform: scale(1.05);
color: var(--weber);
text-decoration: underline;
}

.footer > .footer_elements > img {
    height: 20px;
}

@media (max-width: 1080px) {
    .footer > .footer_elements > p {
        display: none;
    }

    .footer > .footer_elements > img {
        display: none;
    } 


}

