/* Wrapper für die gesamte Komponente */
.backend-analytics {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .backend-analytics h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Liste der Einträge */
  .backend-analytics-entry-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  /* Einzelner Eintrag */
  .backend-analytics-entry-container {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .backend-analytics-entry-container:hover {
    transform: scale(1.03);
  }
  
  /* Titel des Eintrags */
  .backend-analytics-entry-container h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #005500;
  }
  
  /* Textdetails */
  .backend-analytics-entry-container p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  /* Bildvorschau */
  .backend-analytics-entry-container img {
    margin-top: 10px;
    border-radius: 8px;
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
    border: 1px solid #ddd;
  }
  