.home_slice {
    display: flex;
    margin-top: 8%;
}

.home_slice_background {
    display: flex;
    width: 100%;
    justify-content: space-around;
    background-image: linear-gradient(rgba(12, 22, 15, 0.733), rgba(19, 11, 0, 0.856)), url("./img/Handgemacht.jpg");
    height: 25%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}


.home_slice_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 40%;
}

.home_slice_text > h2 {
    font-size: 42px;
    color: white;
}

.home_slice_text > .link_button {
    width: 250px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: var(--weber);
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 20px;
    cursor: pointer;
    transition: all 300ms ease;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_slice_text > .link_button:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.35);
}

.home_slice_pic > img {
    margin: 10% 0 10% 0;
    width: 500px;
    border-radius: 10px;
}


@media (max-width:840px) {

    .home_slice {
        margin-top: 0;
        margin-bottom: 10%;
    }
    .home_slice_background {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8%;
        flex-direction: column-reverse;
    }

    .home_slice_text {
   
        max-width: 100%;
        margin-left: 10%;
        margin-bottom: 60px;
    }

.home_slice_text > h2 {
    font-size: 32px;
    color: white;
    margin-right: 30px;
}

.home_slice_pic > img {
    width: 80vw;
    border-radius: 5px;
    margin: 10% 0 5% 0;
}

.home_slice_text > .link_button {
    margin-top: 5%;
    border-radius: 5px;
}

}