

.agbs_text {
    margin: 50px 20% 20px 6%;
} 

.agbs_text > h1 {
    margin-bottom: 10px;
} 

.agbs_text > h3 {
    margin-bottom: 20px;
} 