.home_products {
    width: 100vw;
    margin: 4% 0 0 0; 
    align-items: center;
    justify-content: center;
}


.home_products > h3 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 4%;
}

.home_products > hr {
    margin-left: 48%;
    margin-right: 48%;
}

.home_product_row {
    justify-content: space-evenly;
    display: flex;
}


.home_product_button1 {
    display: flex;
    margin: 30px 0 7vh 0;
    justify-content: center;
}

.home_product_button1 > button {
   
    width: 250px;
    height: 45px;
    font-weight: 300;
    background-color: rgb(88, 88, 88);
    color: white;
    border-radius: 100px;
    transition: 100ms ease-in;
    margin-top: 4%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-family: inherit;
    font-size: 16px;
    display: none;
}

.home_product_button1 > button:hover {
    transform: scale(1.02);
    cursor: pointer;
    background-color: var(--weber)
}

.home_product_button_none {
    display: none;
}

.home_product_none {
    display: none;
}

@media (max-width:840px) {

.home_product_row { 
width: 100%;
display: grid;
grid-template-columns: repeat(2, 2fr);
justify-content: space-evenly;
align-content: center;
justify-items: center;
align-items: center;
}

.home_products > h3 {
    font-size: 22px;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
}

}