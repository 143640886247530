.ueber_uns {
    display: flex;
    width: 100vw;
    margin-top: 5%;
    margin-bottom: 5%;
}

.ueber_uns_pic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 6vw;
    
}

.swiper_carousel {
    width: 40vw;
    margin-bottom: 20px;
}

.ueber_uns_pic > img {
    width: 40vw;
    margin-top: 5%;
    
}

.ueber_uns_text {
    margin: 0px 6vw 20px;
}

.ueber_uns_text > p {
    font-size: 15px;
}



.ueber_uns_text > p > span {
    font-weight: bold;
    color: var(--weber);
}


@media (max-width: 850px) {
    .ueber_uns {
        display: flex;
       flex-direction: column;
       justify-content: center;
    }

    .ueber_uns_pic > img {
        display: none;
    }

    .swiper_carousel {
        width: 88vw;
        margin-bottom: 20px;
    }

    .ueber_uns_pic {
       
        margin-left: 0vw; 
    }

}