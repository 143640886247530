.produkt_info {
    display: flex;
    margin-top: 3%;
}



.produkt_page {
   max-width: 55%;
   margin-left: 6%;
} 

.produkt_page_2 {
    max-width: 55%;
    margin-left: 6%;
 } 

.produkt_page > h1 {
    font-size: 28px;
}

.produkt_page > p {
    margin-right: 50px;
}

.produkt_page > hr {
    width: 10%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.produkt_fotos > img {
    max-width: 90%;
    margin: 10px 0 0px 0;
}

.produkt_fotos {
    margin-top: 2%;
}

.produkt_info_feature {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.produkt_info_feature > img {
    width: 25px;
    margin-right: 15px;
} 

.produkt_info_feature > p {
    font-weight: 700;
}


@media (max-width: 1050px) {

    .produkt_info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.produkt_page {
    max-width: 100%;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 5%;
 } 

 .produkt_fotos > img {
    max-width: 100%;
    
}


}