
.home_info_container_1 {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;
    padding-top: 7vh;
    padding-bottom: 7vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.87), rgba(238, 238, 238, 0.925)),  url("./img/Firma_Himbergerstraße.jpg");
    height: 25%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.home_info_left_1 {
    width: 53vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.home_info_left_1 > img {
    width: 80%;
    max-height: 450px;
    border-radius: 5px;
    object-fit: cover;
}

.home_info_right_1 {
    margin-right: 6%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.home_info_right_1 > h2 {
margin: 0px 0 20px 0;
font-size: 48px;
font-weight: 600;
color: var(--weber);

}

.home_info_right_1 > h4 {
    margin-bottom: 5px;
    font-weight: 700;
}

.home_info_right_neu {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
}

.home_info_right_1 > .link_button {
    width: 250px;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: var(--weber);
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 5%;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 300ms ease;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_info_right_1 > .link_button:hover {
 
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.35);
}

@media (max-width: 840px) {

    .home_info_container_1 {
        display: flex;
        flex-direction: column;
    }

    .home_info_left_1 {
        width: 100vw;
        margin-left: 0px;
    }

    .home_info_left_1 > img {
        width: 84%;
        margin-bottom: 40px;
        max-height: none;
        border-radius: 15px;
        object-fit: cover;
    }

    .home_info_right_1 {
        margin-right: 6%;
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 6%;
    }

    .home_info_right_1 > h2 {
      
        font-size: 2.5rem;

        }

        .home_info_right_1 > .link_button {
            border-radius: 5px;
        }

        .home_info_left_1 > img {
            border-radius: 5px;
        }
    

}